import React from "react"
import Menu from "./menu"

const PostHeader = ({title, subtitle, image}) => (
	<>
		<div className="container-fluid px-md-5">
			<nav className="navbar navbar-expand-lg navbar-light">
				<Menu home={false} />
			</nav>
		</div>
		<div style={{position: 'relative', zIndex: -1}}>
			<div className="bg-red-gradient angle-stripe-background"></div>
			<div className="angle-stripe-content">
				<div className="container h-100">
					<div className="row h-100">
						<div className="col-12 my-auto order-last col-sm-7 order-sm-first">
							<h1 className="display-4 text-white text-shadow font-weight-bolder text-wrap">{title}</h1>
							<h3 className="text-white text-shadow serif-font">{subtitle}</h3>
						</div>
						<div className="col-7 mx-auto order-first col-sm-5 my-auto order-sm-last mb-5">
							<img src={image} className="img-fluid img-splash shadow mx-auto" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
)

export default PostHeader