import React from "react"
import Carousel from "../carousel"
import stories from '../../data/stories'

const OurStory = () => (
	<div style={{position: 'relative', zIndex: 0}}>
		<div id="about_us" className="angle-full-background"></div>
		<div className="angle-full-content d-flex align-items-center">
			<div className="container py-5 px-0">
				<h1 className="text-white serif-font text-center my-5">L'histoire de KAATSU</h1>
				<div className="bg-dark text-white shadow">
					<Carousel id="historyCarousel" name="" indicators={true}>
						{stories.map((item, k) => (
							<div className={k === 0 ? "carousel-item p-5 active" : "carousel-item p-5"}>
								<div className="row align-items-center">
									<div className="col-10 offset-1 col-md-3">
										<img src={item.image} alt={item.image_alt || ''}  className="img-fluid rounded-circle" />
									</div>
									<div className="col-10 offset-1 col-md-7 offset-md-0">
										<h3 className="my-4">{item.title}</h3>
										<p>{item.text}</p>
									</div>
								</div>
							</div>
						))}
					</Carousel>
				</div>
			</div>
		</div>
	</div>
)

export default OurStory