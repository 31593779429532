import l from '../lang/lang'

export default [
	{
		image: '/assets/images/our_story/overview.jpg',
		title: l('Overview', 'fr', 'our-story-title-1'),
		text: l(`KAATSU - specifically the KAATSU Constant Mode - 
			was first envisioned in 1966 by Dr. Yoshiaki Sato in Tokyo. The KAATSU Cycle was subsequently developed 
			in 1973 as a means to recover from injuries and increase compliance and ease-of-use. 
			Rigorous academic study and medical research on KAATSU began in 1995 at the University of Tokyo. 
			Its adoption in Japan transformed the effects of traditional athletic training, speed of rehabilitation, and recovery. 
			After decades of experimenting and proving enhancement of blood circulation and increased hormonal production through 
			practical, noninvasive protocols, KAATSU established the BFR market. KAATSU remains the BFR market's global leader with 
			an unparalleled safety record and widespread adoption in the athletic performance, rehabilitation, and recovery markets.`,
			'fr', 'our-story-text-1'
		)
	},
	{
		image: '/assets/images/our_story/moment_of_discovery.jpg',
		title: l('Moment of Discovery', 'fr', 'our-story-title-2'),
		text: l(`In 1966, Yoshiaki Sato's legs felt numb while he was sitting on a tatami mat floor in the traditional 
		Japanese posture ("seiza"). He realized that his blood circulation was blocked in his lower legs. This moment of 
		inspiration led him to create the original concept of blood flow restriction training and to pursue a lifetime of 
		exploration, research and discovery in the field.`, 'fr', 'our-story-text-2')
	},
	{
		image: '/assets/images/our_story/experimentation.jpg',
		title: l('Experimentation', 'fr', 'our-story-title-3'),
		text: l(`Between 1966 and 1973, Sato continued to self-experiment daily on himself. He compiled detailed data 
		while applying different sizes of bicycle tubes, ropes and bands at different pressures on various parts of his body. 
		He methodically kept track of what type of bands and pressures were safe and worked best.`, 'fr', 'our-story-text-3')
	},
	{
		image: '/assets/images/our_story/moment_of_proof.jpg',
		title: l('Moment of Proof', 'fr', 'our-story-title-4'),
		text: l(`In 1973, Sato fractured his ankle and tore the ligaments around his knee. With a plaster cast, he rehabilitated 
		himself with his early KAATSU bands by repeatedly applying pressure on and off while doing isometric exercises for 30 
		seconds on and a few seconds off three times per day. This was the first application of the KAATSU Cycle Mode that prevented 
		muscle atrophy during his rehabilitation.`, 'fr', 'our-story-text-4')
	},
	{
		image: '/assets/images/our_story/patenting_the_process.jpg',
		title: l('Patenting the Process', 'fr', 'our-story-title-5'),
		text: l(`In 1994, Sato applied for his first patents in Japan (Patent No. 2670421), U.S.A. (Patent No. 6149618), 
		and Europe (UK, Germany, France, Italy with 94206403.0) as he began to manufacture and commercialize the first KAATSU 
		bands in Japan.`, 'fr', 'our-story-text-5')
	},
	{
		image: '/assets/images/our_story/kaatsu_specialist_certification_program.jpg',
		title: l('KAATSU Specialist Certification Program', 'fr', 'our-story-title-6'),
		text: l(`In 1997, KAATSU introduced the KAATSU Specialist Education Program in Japan. He also began 
		research and clinical tests at the University of Tokyo Hospital at the 22nd Century Medical and Research Center. 
		The program was expanded in the United States and Europe in 2014.`, 'fr', 'our-story-text-6')
	},
	{
		image: '/assets/images/our_story/kaatsu_global_expansion.jpg',
		title: l('KAATSU Global Expansion', 'fr', 'our-story-title-7'),
		text: l(`Since used by the USA Winter Olympic Team in 2014, the U.S. military, and athletes at the 2016 
		Rio Olympic Games, KAATSU has expanded to 49 countries in Europe, North America, South America, Asia, Oceania 
		and Africa - and by Mount Everest climbers and English Channel swimmers as well as paraplegics, people up to 
		104 years, and individuals of all ages and backgrounds who want to lose weight and gain muscle tone.`,
			'fr', 'our-story-text-7')
	}
]