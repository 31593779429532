import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/post-layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

// Components
import PostHeader from "../components/post-header"
import FeaturedIn from "../components/blocks/featured_in"
import Testimonials from "../components/blocks/testimonials"
import Applications from "../components/blocks/applications"
import LearnMore from "../components/blocks/learn-more"
import Supporters from "../components/blocks/supporters"
import HomeText from "../components/blocks/home-text"
import HomeImages from "../components/blocks/home-images"
import OurStory from "../components/blocks/our-story"

const components = {
	'PostHeader': PostHeader, 'FeaturedIn': FeaturedIn, 'Testimonials': Testimonials, 'Applications': Applications,
	'LearnMore': LearnMore, 'Supporters': Supporters, 'Link': Link, 'HomeText': HomeText, 'HomeImages': HomeImages,
	'OurStory': OurStory
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body } = mdx
  return (
	<Layout>
		<SEO title={frontmatter.title} />
		<PostHeader
			title={frontmatter.title}
			subtitle={frontmatter.subtitle}
			image={frontmatter.image}
		/>
		{/*<div className="container">*/}
			<MDXProvider components={components}>
				<MDXRenderer>{body}</MDXRenderer>
			</MDXProvider>
		{/*</div>*/}
	</Layout>
  )
}

// fetch the right page by it's slug, comming from context value passed in gatsby-node.js
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        image
      }
    }
  }
`